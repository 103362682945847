import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import MainApp from "./MainApp";
import theme from "./theme";
import { Provider } from "react-redux";

import { store } from "./app/store";
import AppUpdate from "./AppUpdate";

const root = ReactDOM.createRoot(document.getElementById("root")); // Create root
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <Provider store={store}>
        <AppUpdate>
          <MainApp />
        </AppUpdate>
      </Provider>
    </StyledEngineProvider>
  </ThemeProvider>
  // </React.StrictMode>
);
