import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          color: "#880E4F",
          fontWeight: "bold",
        },
      },
    },
  },
  typography: {
    fontFamily: "Lato, sans-serif",
  },
  palette: {
    primary: {
      main: "#4A148C",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#880E4F",
      contrastText: "#FFFFFF",
    },
    warning: {
      main: "#FFC400",
    },
    error: {
      main: "#FF3D00",
    },
    info: {
      main: "#01579B",
    },
    success: {
      main: "#64DD17",
      contrastText: "#FFFFFF",
    },
    text: {
      disabled: "rgba(0,0,0,0.65)",
      primary: "rgba(0, 0, 0, 0.95)",
    },
  },
});
export default theme;

